<template></template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import '@amcharts/amcharts4/charts'
import * as am4editor from '@amcharts/editor4'
// let chart = am4core.Sprite
// let launcher = am4editor.EditorLauncher
am4editor.ILauncherConfig = {
  editorUrl: '/am4editor/',
  target: { type: 'inline' }
}
am4editor.IConfig = {
  enabledModules: ['design'],
  engineConfig: {
    // availableThemes: [
    //   {
    //     name: 'am4themes_animated',
    //     label: 'Animated'
    //   },
    //   {
    //     name: 'am4themes_dark',
    //     label: 'Dark'
    //   }
    // ]
  }
}
import {
  // mapMutations as mapRootMutations,
  createNamespacedHelpers as cnh
} from 'vuex'

const { mapGetters, mapActions } = cnh('ElmentBuilderStore')
export default {
  data() {
    return {
      editorConfig: am4editor.IConfig,
      launcherSettings: am4editor.ILauncherConfig,
      launcher: null,
      chartConfig: false,
      formData: false
    }
  },
  async created() {
    await this.makeChartConfig()
    this.launchEditor()
  },
  computed: {
    ...mapGetters(['selectedElement', 'selectedGraphConfig'])
  },
  methods: {
    ...mapActions(['setGraphConfiguration', 'getExportData']),
    launchEditor() {
      this.launcher = new am4editor.EditorLauncher(this.launcherSettings)
      this.launcher.addEventListener('save', this.saveChart)
      this.launcher.addEventListener('close', this.closeEditor)
      const editorConfig = JSON.parse(JSON.stringify(this.editorConfig))
      editorConfig.allowDefaultTemplates = true
      this.launcher.editorConfig = editorConfig
      this.launcher.launch({ ...this.chartConfig })
    },
    async makeChartConfig() {
      this.selectedElement.configuration.strokeWidth = 0
      this.chartConfig = JSON.parse(
        JSON.stringify(this.selectedElement.configuration || {})
      )
      // console.log("this.chartConfig", this.chartConfig)
      this.formData = this.chartConfig.formData
      // console.log(this.formData, this.chartConfig.formData)
      if (
        this.selectedElement.dataSource.headers &&
        this.selectedElement.dataSource.headers.length
      ) {
        if (this.selectedElement.dataSource.sources_link) {
          let ecData = await this.getExportData({
            name: this.selectedElement.dataSource.sources_link,
            params: {}
          })
          if (ecData.ok) {
            this.chartConfig.data = JSON.parse(
              JSON.stringify(ecData.info || [])
            )
          } else {
            this.chartConfig.data = this.$Chelper.clone([])
          }
        } else if (this.selectedElement.dataSource.rows.length) {
          this.chartConfig.data = this.$Chelper.clone(this.selectedElement.dataSource.rows)
        } else {
          this.chartConfig.data = this.$Chelper.clone([])
        }
      }
      console.log(`[ this.chartConfig]: `, this.chartConfig)
    },
    mapHeaderAndData(data, header) {
      let res = []
      header.forEach((el) => {
        data.forEach((row, idx) => {
          // console.log(`[row]: `, row)
          // console.log(`[row[el.value]]: `, row, el.header)
          if (!row.hasOwnProperty(el.header)) {
            row[el.header] =
              el.type == 'Number'
                ? Math.round(Math.random() * 100)
                : 'CAT ' + idx
          } else {
            // console.log(el.header)
            // delete row[el.header]
          }
        })
      })
      return data
    },
    makeDataProvider() {
      const maxLen = 5
      let dataProvider = []
      let columns = this.selectedElement.dataSource.headers
      for (let i = 0; i < maxLen; i++) {
        let obj = {}
        columns.forEach((el, idx) => {
          switch (el.type) {
            case 'Text':
              obj[el.header] = el.header + '-' + i
              break
            case 'Number':
              obj[el.header] = (i + idx + (el.id || 1)) * 10
              break
            default:
              break
          }
        })
        dataProvider.push(obj)
      }
      this.chartConfig.data = dataProvider
    },
    saveChart(event) {
      console.log('[event]: ', event)
      if (event) {
        this.chartConfig = event.chartConfig
        this.chartConfig['formData'] = this.formData
      }
      // console.log(this.chartConfig)
      this.setGraphConfiguration(JSON.parse(JSON.stringify(this.chartConfig)))
      this.closeEditor()
    },
    closeEditor() {
      if (this.launcher) {
        this.$parent.selectedSettingVal = 'Data'
        this.launcher.close()
      }
    }
  }
}
</script>